/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';
import Image from '../../shared/Image';

// Styles
import './index.scss';
/* -------------------------------------------------------------------------- */
/*                               Implementation                               */
/* -------------------------------------------------------------------------- */
function DownLoadApp() {
  /* ---------------------------------- HOOKS --------------------------------- */

  // Localization
  const { t } = useTranslation();
  /**
   * @returns a function that returns the OS type of the current  mobile device
   */
  function getMobileOperatingSystem() {
    if (typeof window !== 'undefined') {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
      }

      if (/android/i.test(userAgent)) {
        return 'Android';
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
      }
    }

    return 'unknown';
  }
  const deviceType = getMobileOperatingSystem();
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Container className="download-app-container">
      <Row>
        <Col className="title">
          <h1>{t('download')}</h1>
        </Col>
      </Row>
      <Row className="download-icons">
        <Col className="buttons-container">
          {deviceType === 'Android' && (
            <Image src="googlePlay.png" loading="lazy" alt="google" />
          )}
          {deviceType === 'iOS' && (
            <Image src="appStore.png" loading="lazy" alt="apple" />
          )}
        </Col>
      </Row>
    </Container>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["DownloadApp", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default DownLoadApp;
